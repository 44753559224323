import React, { useId } from 'react';

export function CreditCardSvg({ type = '', title }) {
    const titleId = title !== undefined
        ? 'cc-logo-' + useId()
        : undefined;
    let Icon;
    switch (type) {
        case 'visa':
            Icon = Visa;
            break;
        case 'amex':
            Icon = Amex;
            break;
        case 'masterCard':
            Icon = MasterCard;
            break;
        default: return null;
    }
    return (
        <svg role="img" height="24" width="24" aria-labelledby={titleId} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            {title === undefined ? null : <title id={titleId}>{title}</title>}
            <Icon />
        </svg>
    )
}

const Visa = () => (
    <>
        <g fill="none" fillRule="evenodd">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.564 0h18.872c.892 0 1.215.093 1.54.267.327.174.583.43.757.756s.267.65.267 1.54v18.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757s-.65.267-1.54.267H2.563c-.892 0-1.215-.093-1.54-.267a1.8 1.8 0 0 1-.757-.756C.093 22.65 0 22.327 0 21.437V2.563c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757S1.673 0 2.563 0" fill="#1434CB"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.982 11.258c-.01.767.735 1.194 1.296 1.448.577.261.77.428.769.662-.005.357-.46.514-.887.52-.745.01-1.177-.187-1.521-.336l-.268 1.166c.345.148.984.276 1.647.282 1.555 0 2.573-.713 2.579-1.82.006-1.404-2.09-1.481-2.076-2.109.005-.19.2-.393.628-.445a3 3 0 0 1 1.461.238l.26-1.128a4.2 4.2 0 0 0-1.386-.236c-1.464 0-2.494.723-2.502 1.758m6.39-1.66a.68.68 0 0 0-.63.39l-2.222 4.93h1.554l.31-.795h1.9l.18.794h1.37l-1.196-5.32zm.218 1.436.449 1.998h-1.23zm-8.493-1.437-1.226 5.32h1.482l1.225-5.32zm-2.192 0-1.542 3.621-.624-3.079a.68.68 0 0 0-.683-.542h-2.52l-.036.155c.518.104 1.105.272 1.462.452.218.11.28.206.351.468l1.182 4.245H7.06l2.4-5.32z" fill="#fff"/>
        </g>
    </>
);

const Amex = () => (
    <>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.564 0h18.872c.892 0 1.215.093 1.54.267.327.174.583.43.757.756s.267.65.267 1.54v18.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757s-.65.267-1.54.267H2.563c-.892 0-1.215-.093-1.54-.267a1.8 1.8 0 0 1-.757-.756C.093 22.65 0 22.327 0 21.437V2.563c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757S1.673 0 2.563 0" fill="#016FD0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="m10.677 19.482 8.26.018 1.298-1.483 1.306 1.477H24V17.33l-1.54-1.702L24 13.941v-2.119l-13.323-.012z" fill="#fff"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.567 18.64v-5.986h4.953v1.38h-3.35v.935h3.27v1.357h-3.27v.92h3.35v1.395z" fill="#006FCF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="m21.97 12.654-1.68 1.898-1.674-1.898h-2.121l2.74 2.99-2.74 2.997h2.063l1.687-1.917 1.704 1.917H24v-.085l-2.681-2.912L24 12.701v-.047z" fill="#006FCF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="m20.973 5-.687 1.967L19.603 5h-3.965v2.625L14.51 5h-3.212L8 12.672h16V5z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="m11.906 5.834-2.591 5.981h1.777l.49-1.197h2.649l.488 1.197h1.822l-2.58-5.981zm.223 3.441.777-1.904.777 1.904z" fill="#006FCF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.517 11.814h1.571V7.705l1.491 4.11h1.413l1.46-4.096v4.11L24 11.813v-5.98h-2.405l-1.294 3.686-1.286-3.678-2.498-.008z" fill="#006FCF"/>
    </>
);

const MasterCard = () => (
    <>
        <g fill="none" fillRule="evenodd">
            <path d="M22 0H2a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2" fill="#050505"/>
            <path d="M14.903 7.44H9.099v9.12h5.804z" fill="#FF5F00"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.697 12c-.002-1.78.847-3.46 2.302-4.56-2.471-1.868-6.02-1.596-8.153.626a5.64 5.64 0 0 0 0 7.87C5.98 18.157 9.528 18.43 12 16.56c-1.456-1.1-2.305-2.78-2.303-4.56" fill="#EB001B"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.753 12c0 2.22-1.318 4.246-3.394 5.217s-4.546.715-6.36-.658c1.454-1.1 2.303-2.78 2.303-4.56S13.453 8.54 12 7.44c1.814-1.372 4.284-1.628 6.36-.657s3.394 2.996 3.394 5.216z" fill="#F79E1B"/>
        </g>
    </>
);
