import React from 'react';
import { TextStyle } from '@able/react';
import AUTORECHARGE_LOCALE from '../../../assets/locale/autorecharge-locale';
import './autorechargeenabled.css';
import { getBrandDetails, getAREnabledAmountDescription } from '../../../adapters/utils';
import { addDataLayerEventInfo } from '../../../adapters/analytics-utils';
import defaultImgSrc from '../../../assets/img/calendar-icon.svg';
import { BRANDS } from '../../../adapters/constants';

function AutoRechargeEnabledCard(props) {
    const {
        loggedIn = false, hasTdiLogin = false, newOfferSelected: {
            autoRechargeBonusData = '', autoRechargePercentage = '', autoRechargePrice = '', num
        } = {}, actualAmount = 0, starterCredit = 0, priceBeforeDiscount = 0, surcharge = {}, isSurchargeAPIDown, isDebitCard
    } = props;
    const {
        AutoRechargeEnabledCard: {
            autoRechargeContent, authPara1 = '', unauthpara1 = '', title = ''
        }
    } = AUTORECHARGE_LOCALE;
    const price = priceBeforeDiscount || actualAmount || starterCredit;
    const isJBHiFi = getBrandDetails().brandName === BRANDS.JBHiFi.name;
    if (!hasTdiLogin) {
        addDataLayerEventInfo('impression', 'auto recharge module', 'success', 'Auto recharge enabled - New TDI');
    } else {
        addDataLayerEventInfo('impression', 'auto recharge module', 'success', 'Auto recharge enabled - Existing TDI');
    }
    return (
        <div className="auto-recharge-details">
            <img className="cal-image" alt="Calender" src={defaultImgSrc} />
            <TextStyle className="pt-24 mtxxl" element="h3" alias="HeadingC">
                {title}
            </TextStyle>
            <hr />
            <TextStyle className="mtxxl" alias="TextBodyShort">
                {getAREnabledAmountDescription({ para: autoRechargeContent, isDebitCard, plan: props.newOfferSelected, isSurchargeAPIDown, surcharge })}
            </TextStyle>
            <TextStyle className="mtxxl" alias="TextBodyShort">
                {autoRechargeBonusData
                    ? `You'll receive ${autoRechargeBonusData} bonus data on your next ${num} recharges as part of your auto recharge special offer.`
                    : autoRechargePrice
                        ? `You'll receive ${autoRechargePrice} off your next ${num} recharges.` : autoRechargePercentage
                            ? `You'll receive ${autoRechargePercentage} off your next ${num} recharges.`
                            : null}
            </TextStyle>
            <TextStyle className="mtxxl" alias="TextBodyShort">
                {loggedIn || hasTdiLogin ? authPara1 + (isJBHiFi ? `username to log in to JB Hi-Fi Mobile.` : `Telstra ID to log in to My Telstra.`) + ` Look out for the verification email to get started.` : unauthpara1}
            </TextStyle>
        </div>
    );
}

export default AutoRechargeEnabledCard;
