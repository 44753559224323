import { Icon, Spacing, Surface, TextStyle } from '@able/react';
import React, { Fragment } from 'react';
import { CreditCardSvg } from '../CreditCardSvg';
import { getAbleSpriteSheet } from '../../adapters/utils';

const ableSpriteSheet = getAbleSpriteSheet();
const SubmittedCardEdit = ({ paymentDetailsConfirmationCard, isDebitCard, resetSurchargeSection, ccLogoMap }) => {

    const getCardTypeText = (cardType) => {
        switch(cardType) {
            case 'MASTER_CARD':
                return 'Mastercard';
            case 'VISA':
                return 'Visa';
            case 'AMERICAN EXPRESS':
                return 'American Express'    
        }
    }

    return (
        <Fragment>
            <Surface
                background="materialBasePrimary"
                className="submittedCard eg-surface"
                cornerRadius="cornerRadiusSoft"
                variant="SurfaceSlight"
                interactive
            >
                <Spacing
                    bottom="spacing2x"
                    left="spacing2x"
                    right="spacing2x"
                    top="spacing2x"
                >
                    <a href="javascript:void(0)" onClick={() => resetSurchargeSection()}>
                        <div className="submittedCardContainer">
                            <div className="submittedCardIcon">
                            {paymentDetailsConfirmationCard?.cardType?.toUpperCase() === 'AMERICAN EXPRESS' ? 
                                <CreditCardSvg type={ccLogoMap.AMEX.logo} title={ccLogoMap.AMEX.title}/> :
                                <CreditCardSvg
                                    type={ccLogoMap[paymentDetailsConfirmationCard?.cardType?.toUpperCase()]?.logo} 
                                    title={ccLogoMap[paymentDetailsConfirmationCard?.cardType?.toUpperCase()]?.title} />}
                            </div>
                            <div className="submittedCardInfoContainer">
                                <TextStyle alias="HeadingD" className="mt-1">{getCardTypeText(paymentDetailsConfirmationCard?.cardType?.toUpperCase())}</TextStyle>
                                <TextStyle alias="TextBodyShort">{isDebitCard ? `Debit` : 'Credit'} card ending in {paymentDetailsConfirmationCard.last4}</TextStyle>
                                <TextStyle alias="FinePrint" className="mt-1" colour="Subtle">Expiry {paymentDetailsConfirmationCard.expiryMonth}/{paymentDetailsConfirmationCard.expiryYear}</TextStyle>
                            </div> 
                            <div className="submittedCardEditIcon">
                                <Icon icon='Edit' size='24' developmentUrl={ableSpriteSheet} className="CardEditIcon"/>
                            </div>
                        </div>
                    </a>
                </Spacing>
            </Surface>
        </Fragment>  
    );

}
export default SubmittedCardEdit;