import React, { useState } from 'react';
import tick from '../../../assets/img/svg/icon-controls-ui-tick.svg';
import { formatMobileNumber } from '../../../adapters/utils';
import { TextStyle, InlineLink } from '@able/react';

const SelectedRechargeDetails = (props) => {
    let usageQuota;
    const { starterCredit } = props.appData.serviceNumber;
    if (props.plan.usageQuota) {
        usageQuota = (
            <React.Fragment>
                {props.plan.usageQuota.map((quota) => {
                    return (
                        <div role="listitem" key={quota}>
                            <TextStyle className="mt-2 d-flex" alias="FinePrint">
                                <img className="custom-tick mr-2" aria-hidden="true" src={tick} alt="tick" />
                                {quota}
                            </TextStyle>
                        </div>
                    );
                })}
            </React.Fragment>
        );
    }
    const rechargeHeading = props.actualAmount ? 'Your recharge includes' : 'Your plan includes';
    const discount = props.plan.firstTimeDiscountPercentage || props.plan.firstTimeDiscountPrice;
    return (
        <div tabIndex="-1" className="payment-card" aria-label={`${props.plan.planHeading} plan`}>
            <div>
                <TextStyle element={'h2'} alias="HeadingD">
                    Your service number
                </TextStyle>
                <TextStyle className="mt-2" alias="TextBodyShort">
                    {formatMobileNumber(props.msisdn)}
                </TextStyle>
            </div>
            <div className="mt-3">
                <TextStyle element={'h2'} alias="HeadingD">
                    {rechargeHeading}
                </TextStyle>
                <div role="list">
                    {props.plan.promotion && props.plan.includedBonus && (
                        <div role="listitem">
                            <TextStyle className="my-2 bonus-text d-flex" alias="Label">
                                <img className="custom-tick mr-2" aria-hidden="true" src={tick} alt="tick" />
                                {`${props.plan.includedBonus}`}
                            </TextStyle>
                        </div>
                    )}
                    {discount && (
                        <div role="listitem">
                            <TextStyle className="my-2 bonus-text d-flex" alias="FinePrint">
                                <img className="custom-tick mr-2" aria-hidden="true" src={tick} alt="tick" />
                                {discount} {' OFF'}
                            </TextStyle>
                        </div>
                    )}
                    {starterCredit !== 0 && (
                        <div role="listitem">
                            <TextStyle className="my-2 d-flex" alias="FinePrint">
                                <img className="custom-tick mr-2" aria-hidden="true" src={tick} alt="tick" />${starterCredit}
                            </TextStyle>
                        </div>
                    )}
                    <div role="listitem">
                        <TextStyle className="my-2 d-flex" alias="FinePrint">
                            <img className="custom-tick mr-2" aria-hidden="true" src={tick} alt="tick" />
                            {props.plan.planHeading}
                        </TextStyle>
                    </div>
                    {usageQuota}
                    <div role="listitem">
                        <TextStyle className="my-2 d-flex ml-3" alias="FinePrint">
                            {props.plan.planCondition}
                        </TextStyle>
                    </div>
                    {props.plan.promotion && props.plan.promotionalTemplate && (
                        <div role="listitem">
                            <TextStyle className="my-2 d-flex ml-3" alias="FinePrint">
                                {props.plan.promotionalTemplate}
                            </TextStyle>
                        </div>
                    )}
                    {props.plan.firstTimeTemplate && (
                        <div role="listitem">
                            <TextStyle className="my-2 d-flex ml-3" alias="FinePrint">
                                {props.plan.firstTimeTemplate}
                            </TextStyle>
                        </div>
                    )}
                </div>
            </div>
            {starterCredit === 0 && (
                <div>
                    <hr className="m-3 " />
                    <div className="d-flex info-and-price">
                        <TextStyle className="totalpay order-1" element="span" alias="HeadingD">
                            Recharge Amount
                        </TextStyle>
                        <div>
                            <TextStyle alias="HeadingC">{props.plan.firstTimePrice ? props.plan.firstTimePrice : `$${props.actualAmount}`}</TextStyle>
                            {(props.plan.firstTimeDiscountPrice || props.plan.firstTimeDiscountPercentage) &&
                                <TextStyle alias="TextBigBody" className="mb-2">Was {props.plan.price}</TextStyle>
                            }
                        </div>
                    </div>
                    <div>
                        <InlineLink>
                            <TextStyle alias="FinePrint">
                                <p><a className="button-as-link" role="button" tabIndex="0" onClick={props.toggleModal}>Payment fees may apply</a></p>
                            </TextStyle>
                        </InlineLink>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SelectedRechargeDetails;
