import React, { useRef, useEffect, Fragment, useState } from 'react';
import { TextStyle, ActionButton, IconButton, ConfirmationDialog } from '@able/react';
import Modal from 'react-modal';
import { focusOnModal, getAbleSpriteSheet } from '../../../adapters/utils';

const ableSpriteSheet = getAbleSpriteSheet();

const SurchareRatesModal = ({ toggleModal, modalOpen, surchargeTable = [], surchargeTableError }) => {
    const [filteredSurchargeTable, setFilteredSurchargeTable] = useState([]);
    const modalRef = useRef(null);

    useEffect(() => {
        if (surchargeTable.length > 0) {
            const filteredRates = surchargeTable.filter(rateInfo =>
                rateInfo.paymentMethodType === "PAYPAL" || (rateInfo.paymentMethodType === "CREDIT_CARD" && rateInfo.debit === "NO")
            );
            setFilteredSurchargeTable(filteredRates);
        }
    }, [surchargeTable]);

    useEffect(() => {
        modalRef && modalRef.current && focusOnModal(modalRef, 0);
    }, [modalOpen]);

    const renderSurchargeRates = () => {
        return filteredSurchargeTable.map((rateInfo, index) => (
                <TextStyle alias="TextBodyShort" key={index} className="my-2 fees-grid-row">
                    <span>
                        {rateInfo.paymentMethodType === 'PAYPAL' ? 'PayPal': rateInfo.cardType}
                    </span>
                    <span>
                        {rateInfo.surchargeRate}
                    </span>
                </TextStyle>
        ));
    };
    const renderSurchargeModal = () => {
        return (
            <div className="zone" id="surchare-modal-container">
                <div className="modal-content ppv-modal-content">
                    <div className="modal-header-no-border ppv-header">
                        <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={toggleModal} />
                    </div>
                    <div tabIndex="-1" className="surchare-container">
                        <TextStyle element="h1" tabIndex="-1" alias="HeadingD" className="my-3" id="sucharge-heading">
                            Payment fees
                        </TextStyle>
                        <TextStyle alias="TextBodyShort" className="my-3">
                            Fees may apply depending on your chosen payment method. The payment fees are:
                        </TextStyle>
                        <div className="surcharge-rates-modal">{renderSurchargeRates()}</div>
                        <TextStyle alias="TextBodyShort" className="my-3">
                            There are no fees for payments made using a debit card.
                        </TextStyle>
                        <ActionButton 
                          element="button"
                          variant="MediumEmphasis"
                          label="Close"
                          onClick={toggleModal}
                          className="surcharge-close-btn"
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            {surchargeTableError ?
            <ConfirmationDialog title="We're unable to show the payment processing fees"
                description='Check the total to pay after entering your card details. This may include a payment processing fee.'
                isShowing={modalOpen}
                className="surcharge-rate-confirm-dialog"
                stackButtonOnVXS={false}
                developmentUrl={ableSpriteSheet}
                confirmButtonLabel='OK'
                setHideDialog={toggleModal}
                focusOnCloseRef={modalRef}
            >
            </ConfirmationDialog>
            :
            <Modal
                parentSelector={() => document.querySelector('#modal-ppv')}
                shouldCloseOnOverlayClick={false}
                isOpen={modalOpen}
                className="modal-dialog modal-lg modal-sm ppv-modal"
                contentLabel="Additional fees for credit card and PayPal payments"
                aria={{ labelledby: 'sucharge-heading', modal: 'true', role: 'dialog' }}
                onRequestClose={toggleModal}
                shouldCloseOnEsc
                ref={modalRef}
            >
                {renderSurchargeModal()}
            </Modal>
            }
        </Fragment>
    );
};

export default SurchareRatesModal;
