import * as client from '../../../adapters/client';
import * as errorCodeMeta from '../../../adapters/errorCode';
import * as braintree from 'braintree-web';
import {
    BILLPAY_INIT_ERROR,
    BILLPAY_CARD_ERROR,
    BRAINTREE_DEVICE_DATA,
    BILLPAY_HOSTED_FIELD_INITIALISED,
    FETCH_CARD_LIST_FAILURE,
    FETCH_CARD_LIST_SUCCESS,
    HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME,
    HOSTED_FIELDS_INVALID_FIELD_SELECTOR,
    BRAINTREE_CC_VALIDATION_SUCCESS,
    SAVED_CARD_AUTORECHARGE
    // AUTO_RECHARGE_ERROR
} from '../../../adapters/constants';
import $ from 'jquery';
import { UPDATE_PAYMENT_METHOD, HIDE_LOADING_INDICATOR, SHOW_LOADING_INDICATOR, SAVING_CARD_OPTION, ANALYTICS_PAYMENT_METHOD, SET_AUTO_RECHARGE, RESET_AUTO_RECHARGE, RESET_SURCHARGE_RATES, STORE_BRAINTREE_VALIDATED_DATA, SET_IS_SELECTED_DEBIT_CARD } from '../../../routes/actions';
import store from '../../../store';
import { activateSim } from '../../../routes/app/actions';
import { addDataLayerEventInfo } from '../../../adapters/analytics-utils';

let hostedFields;
let deviceData;

export const getBraintreePaymentInitialised = (payload, card) => {
    const AuthToken = payload.token;
    return async (dispatch) => {
        dispatch({ type: SHOW_LOADING_INDICATOR });
        braintree.client
            .create({
                authorization: AuthToken
            })
            .then((clientInstance) => {
                if (card === 'new') {
                    braintree.hostedFields
                        .create({
                            client: clientInstance,
                            styles: {
                                input: {
                                    color: 'black',
                                    'font-family': 'Arial, sans-serif',
                                    'font-size': '16px'
                                },
                                ':focus': {
                                    color: 'black'
                                },
                                '.valid': {
                                    color: 'black'
                                },
                                '.invalid': {
                                    color: 'black'
                                },
                                select: {
                                    '-webkit-appearance': 'none',
                                    '-moz-appearance': 'none',
                                    appearance: 'none',
                                    'font-family': 'Arial, sans-serif',
                                    'font-size': '16px'
                                }
                            },
                            fields: {
                                number: {
                                    selector: '#cc-number'
                                },
                                cvv: {
                                    selector: '#cc-cvv',
                                    type: 'password',
                                    internalLabel: 'Security code (CVV)'
                                },
                                expirationMonth: {
                                    selector: '#cc-exp-month',
                                    placeholder: 'MM',
                                    select: {
                                        options: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
                                    }
                                },
                                expirationYear: {
                                    selector: '#cc-exp-year',
                                    placeholder: 'YYYY',
                                    select: true
                                }
                            }
                        })
                        .then((hostedFieldInstance) => {
                            hostedFields = hostedFieldInstance;
                            dispatch({ type: HIDE_LOADING_INDICATOR });
                            dispatch({ type: BILLPAY_HOSTED_FIELD_INITIALISED, data: true });
                            // adding error data to local storage for NR to pick up
                            window.localStorage.setItem('paymentInitData', BILLPAY_HOSTED_FIELD_INITIALISED);

                            hostedFields.on('cardTypeChange', (event) => {
                                const cardElements = $('.master-card, .american-express, .visa');
                                if (event.cards && event.cards.length === 1) {
                                    const card = event.cards[0];
                                    cardElements.addClass('credit-card-icons-disable');
                                    $(`.${card.type}`).removeClass('credit-card-icons-disable').addClass('credit-card-icons-enable');
                                } else {
                                    cardElements.removeClass('credit-card-icons-disable').addClass('credit-card-icons-enable');
                                }
                            });
                            hostedFieldInstance.on('validityChange', (event) => {
                                const { payment: { isSurchargeProcessed = {} } } = store.getState();
                                if ( isSurchargeProcessed ){
                                    dispatch({ type: RESET_SURCHARGE_RATES});
                                }
                                const field = event.fields[event.emittedBy];
                                const container = $(field.container);
                                const nextSibling = $(field.container.nextSibling);

                                container.removeClass('is-valid');
                                container.removeClass('is-invalid');
                                nextSibling.removeClass('is-valid');
                                nextSibling.removeClass('is-invalid');
                                const element = document.getElementById('error-month-year');
                                if (field.container.id.includes('cc-number')) {
                                    if (!field.isValid) {
                                        let ccError;
                                        container.addClass('is-invalid');
                                        nextSibling.addClass('is-invalid');
                                        if (field.isEmpty) {
                                            ccError = 'Required field. Please enter a valid 15 or 16 digit card number';
                                        } else {
                                            ccError = 'Invalid input. Please enter a valid 15 or 16 digit card number';
                                        }
                                        document.getElementById('cc-error').childNodes[0].childNodes[1].textContent = ccError;
                                        addDataLayerEventInfo('error', 'Inline', 'unavailable', ccError);
                                    }
                                }
                                if (field.container.id.includes('year')) {
                                    if (!field.isEmpty && !field.isValid) {
                                        element.classList.remove('is-valid');
                                        element.classList.remove('is-invalid');
                                        element.classList.add('is-invalid');
                                        addDataLayerEventInfo('error', 'Inline', 'unavailable', element.textContent);
                                        $('#error-month').remove();
                                        $('#error-year').remove();
                                    } else {
                                        element.classList.remove('is-valid');
                                        element.classList.remove('is-invalid');
                                    }
                                }
                                if (field.container.id.includes('cvv')) {
                                    let cvvError;
                                    if (!field.isValid) {
                                        if (field.isEmpty) {
                                            cvvError = 'Required field. Please enter a valid 3 or 4 digit code.';
                                        } else {
                                            cvvError = 'Invalid input. Please enter a valid 3 or 4 digit code.';
                                        }
                                        document.getElementById('cvv-error').childNodes[0].childNodes[1].textContent = cvvError;
                                        document.getElementById('cvv-error').childNodes[0].classList.add('is-invalid');
                                        addDataLayerEventInfo('error', 'Inline', 'unavailable', cvvError);
                                    } else {
                                        document.getElementById('cvv-error').childNodes[0].childNodes[1].textContent = 'This is the 3 or 4-digit number on your card.';
                                        document.getElementById('cvv-error').childNodes[0].classList.remove('is-invalid');
                                    }
                                }

                                if (field.isValid) {
                                    container.addClass('is-valid');
                                    nextSibling.addClass('is-valid');
                                } else if (field.isPotentiallyValid) {
                                    // skip adding classes if the field is
                                    // not valid, but is potentially valid
                                } else {
                                    container.addClass('is-invalid');
                                    nextSibling.addClass('is-invalid');
                                }
                            });
                        })
                        .catch((error) => {
                            if (error.code !== HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME && error.code !== HOSTED_FIELDS_INVALID_FIELD_SELECTOR) {
                                return dispatch({ type: BILLPAY_INIT_ERROR, error });
                            }
                            // adding error data to local storage for NR to pick up
                            window.localStorage.setItem('paymentInitData', error.code);

                            return null;
                        });
                }
                braintree.dataCollector
                    .create({
                        client: clientInstance,
                        kount: true,
                        paypal: false
                    })
                    .then((dataCollectorInstance) => {
                        deviceData = dataCollectorInstance.deviceData;
                        return dispatch({ type: BRAINTREE_DEVICE_DATA, data: payload });
                    });
            })
            .catch((error) => {
                // adding error data to local storage for NR to pick up
                window.localStorage.setItem('paymentInitData', error.code);
                return dispatch({ type: BILLPAY_INIT_ERROR, error });
            });
    };
};

export const payAndActivateWithNewCard = (paymentData) => {
    return async (dispatch) => {
            paymentData.paymentMethodType = 'CREDIT_CARD';
            //  paymentData.paymentNonce = 'fake-valid-visa-nonce';
            dispatch({ type: SAVING_CARD_OPTION, data: paymentData.registerInVault });
            dispatch({ type: ANALYTICS_PAYMENT_METHOD, data: 'New Card' });
            dispatch({ type: UPDATE_PAYMENT_METHOD, data: 'CREDIT_CARD' });
            dispatch(activateSim({
                deviceData,
                customerUUID: paymentData.customerUUID,
                paymentAmount: paymentData.paymentAmount,
                paymentMethodType: 'CREDIT_CARD',
                identityProfileId: paymentData.identityProfileId,
                paymentType: 'NONCE',
                paymentNonce: paymentData.paymentNonce,
                registerInVault: paymentData.registerInVault
            }));
    };
};

export const payAndActivate = (paymentData) => {
    return async (dispatch) => {
        dispatch({ type: RESET_AUTO_RECHARGE }); // FIX - PA-4628 confirmation modal shows again on submit
        dispatch({ type: SAVING_CARD_OPTION, data: paymentData.registerInVault });
        dispatch({ type: ANALYTICS_PAYMENT_METHOD, data: 'New Card' });
        dispatch({ type: UPDATE_PAYMENT_METHOD, data: 'CREDIT_CARD' });
        dispatch(activateSim(paymentData));
    };
};

export const ccBraintreeValidation = (paymentData, callback) => {      // Braintree validation for new card
    return async (dispatch) => {
        hostedFields.tokenize(async (error, payload) => {
            if (error) {
                if (error.code === 'HOSTED_FIELDS_FIELDS_INVALID' || error.code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
                    const state = hostedFields.getState();
                    let documentId;
                    let errorMessage = '';
                    let errorId = '';
                    let invalidFieldArray;
                    if (error.code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
                        invalidFieldArray = Object.keys(state.fields);
                    } else {
                        invalidFieldArray = error.details.invalidFieldKeys;
                    }
                    hostedFields.focus(invalidFieldArray[0]);
                    invalidFieldArray.forEach((key) => {
                        switch (key) {
                            case 'cvv': {
                                documentId = 'cc-cvv';
                                if (state.fields.cvv.isEmpty) {
                                    errorMessage = 'Required field. Please enter a valid 3 or 4 digit code.';
                                    errorId = 'cvv-error';
                                } else {
                                    errorMessage = 'Invalid field. Please enter a valid 3 or 4 digit code.';
                                    errorId = 'cvv-error';
                                }
                                break;
                            }
                            case 'number': {
                                documentId = 'cc-number';
                                if (state.fields.number.isEmpty) {
                                    errorMessage = 'Required field. Please enter a valid 15 or 16 digit card number';
                                    errorId = 'cc-error';
                                } else {
                                    errorMessage = 'Invalid input. Please enter a valid 15 or 16 digit card number';
                                    errorId = 'cc-error';
                                }
                                break;
                            }
                            case 'expirationMonth': {
                                documentId = 'cc-exp-month';
                                if (state.fields.expirationYear.isEmpty) {
                                    errorMessage = 'Required field. Please select a month.';
                                    errorId = 'error-month';
                                }
                                break;
                            }
                            case 'expirationYear': {
                                documentId = 'cc-exp-year';
                                if (state.fields.expirationYear.isEmpty) {
                                    errorMessage = 'Required field. Please select a year.';
                                    errorId = 'error-year';
                                }
                                break;
                            }
                            default:
                                break;
                        }

                        const docElement = document.getElementById(documentId).nextSibling;
                        if (docElement && docElement.classList != null) {
                            docElement.classList.remove('is-valid');
                            docElement.classList.remove('is-invalid');
                            docElement.classList.add('is-invalid');
                        }
                        if (errorMessage !== '') {
                            document.getElementById(errorId).childNodes[0].childNodes[1].textContent = errorMessage;
                            if (errorId === 'cvv-error') {
                                document.getElementById('cvv-error').childNodes[0].classList.add('is-invalid');
                            }
                            addDataLayerEventInfo('error', 'Inline', 'unavailable', errorMessage);
                        }
                    });
                    callback && callback({ valid: false });
                    return null;
                }
                callback && callback({ valid: false });
                return dispatch({ type: BILLPAY_CARD_ERROR, error });
            }
            paymentData.paymentMethodType = 'CREDIT_CARD';
            paymentData.deviceData = deviceData;
            paymentData.paymentNonce = payload.nonce;
            paymentData.cardType = payload.details.cardType === 'MasterCard' ? 'MASTER_CARD' : payload.details.cardType;
            paymentData.last4 = payload.details.lastFour;
            paymentData.isDebitCard = payload.binData.debit;
            paymentData.expiryMonth = payload.details.expirationMonth;
            paymentData.expiryYear = payload.details.expirationYear;
            callback && callback({ valid: true });
            dispatch({ type: STORE_BRAINTREE_VALIDATED_DATA, paymentData});
            const isSelectedDebitCard = paymentData?.isDebitCard.toLowerCase();
            dispatch({ type: SET_IS_SELECTED_DEBIT_CARD, isSelectedDebitCard });
        });
    };
};

export const payAndActivateAutoRechargeWithNewCard = (paymentData) => {
    return async (dispatch) => {
        dispatch({ type: BRAINTREE_CC_VALIDATION_SUCCESS, paymentData });
    }
}

export const payAndActivateWithSavedCard = (paymentData) => {
    return async (dispatch) => {
        dispatch({ type: ANALYTICS_PAYMENT_METHOD, data: 'Saved Card' });
        dispatch({ type: UPDATE_PAYMENT_METHOD, data: 'CREDIT_CARD' });
        dispatch(activateSim({
            customerUUID: paymentData.customerUUID,
            identityProfileId: 'required',
            paymentAmount: paymentData.paymentAmount,
            paymentType: 'tid',
            paymentMethodType: 'CREDIT_CARD',
            deviceData: deviceData,
            id: paymentData.id
        }));
    };
};

export const setSavedCardAutoRecharge = (payload) => {
    payload.deviceData = deviceData;
    return { type: SAVED_CARD_AUTORECHARGE, savedCardData: payload };
};

/**  **Getting saved Card Details */

export const getSavedCardList = (dataFactory = client.fetchSavedCardList) => {
    return (dispatch) => {
        return dataFactory(dispatch, savedCardFetchSuccess, savedCardFetchFailure);
    };
};

export const savedCardFetchSuccess = (cardListData) => {
    const { hasError, errorCode, data } = processCardListData(cardListData.data);
    return (dispatch) => {
        if (hasError) {
            dispatch(savedCardFetchFailure(errorCode));
        } else {
            dispatch({
                type: FETCH_CARD_LIST_SUCCESS,
                data
            });
            if (!data || (data && data.data && data.data.length === 0)) {
                const state = store.getState();
                const {
                    payment: { braintree: { token } = {} }
                } = state;
                const payload = {
                    token
                };
                dispatch(getBraintreePaymentInitialised(payload, 'new'));
            }
        }
    };
};

const processCardListData = (savedCardList) => {
    let errorCode = '';
    if (!savedCardList) {
        errorCode = errorCodeMeta.SERVER_ERROR;
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: savedCardList };
};

export const savedCardFetchFailure = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 400:
            errorCode = errorCodeMeta.BAD_REQUEST;
            break;
        case 404:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 405:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    return (dispatch) => {
        dispatch({ type: FETCH_CARD_LIST_FAILURE, errorCode });
        const state = store.getState();
        const {
            payment: { braintree: { token } = {} }
        } = state;
        const payload = {
            token
        };
        dispatch(getBraintreePaymentInitialised(payload, 'new'));
    };
};

export const resetHostedFields = () => {
    return (dispatch) => {
        dispatch({ type: BILLPAY_HOSTED_FIELD_INITIALISED, data: false });
    };
};

export function setAutoRecharge(value) {
    return {
        type: SET_AUTO_RECHARGE,
        value
    };
}

export function resetAutoRecharge() {
    return {
        type: RESET_AUTO_RECHARGE
    };
}

export const setIsSelectedDebitCard = (isSelectedDebitCard) => {
    return (dispatch) => {
        dispatch({ type: SET_IS_SELECTED_DEBIT_CARD, isSelectedDebitCard });
    }
};