import './billpay.css';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { MessageSection, TextStyle, MessageInline } from '@able/react';
import { PAYMENT_ERROR } from '../../../adapters/errorCode';
import lock from '../../../assets/img/svg/controls-lock.svg';
import { addCartLayer, addDataLayerCartPrice, addDataLayerEventInfo } from '../../../adapters/analytics-utils';
import { getAbleSpriteSheet, getSepratedSurchargeData } from '../../../adapters/utils';
import axios from 'axios';

const ableSpriteSheet = getAbleSpriteSheet();
let surchargeCallSource;
class Paypal extends Component {
    constructor() {
        super();
        const env = window.appConfig.PPA_ENV_MODE;
        let paypalEnv;
        if (env === 'prod' || env === 'staging') {
            paypalEnv = 'production';
        } else {
            paypalEnv = 'sandbox';
        }
        this.state = {
            env: paypalEnv,
            commit: true,
            style: {
                size: 'responsive', // small | medium | large | responsive
                shape: 'rect', // pill | rect
                color: 'gold', // gold | blue | silver | black
                tagline: false,
                layout: 'horizontal'
            }
        };
    }

    componentWillMount() {
        const { getBraintreePaymentInitialised, resetHostedFields } = this.props.actions;
        resetHostedFields();
        const {
            payment: { braintree: { token } = {} }
        } = this.props.state;
        const payload = {
            token
        };
        addCartLayer();
        // addDataLayerCartPrice('paymentType', 'PayPal');
        // addDataLayerEventInfo('paypalPaymentMethod');
        getBraintreePaymentInitialised(payload);
    }

    componentDidUpdate() {
        const { errors } = this.props.state.payment;
        if (errors && (errors.errorCode === PAYMENT_ERROR)) {
            document.getElementById('server-error-description').focus();
        }
    }

    render() {
        const PayPalButton = window.paypal_sdk && window.paypal_sdk.Buttons.driver('react', { React, ReactDOM });
        const paypalSurchargeRate = this.props.surchargeTable.length > 0
            ? `of ${this.props.surchargeTable.find(surcharge => surcharge.paymentMethodType === "PAYPAL")?.surchargeRate}`
            : '' ;
        return (
            <div className="Paypal-form">
                {this.renderErrors()}
                <div className='mb4xl'>
                    <MessageInline
                        developmentUrl={ableSpriteSheet}
                        variant="Information"
                        description={`A processing fee ${paypalSurchargeRate} applies for PayPal payments. Check the total to pay in PayPal.`}
                    />
                </div>
                <TextStyle className="Paypal-body" alias="TextBodyShort">
                    You&apos;ll be redirected to PayPal to securely complete your payment.
                </TextStyle>
                <div className="Paypal-button">
                    {PayPalButton && <PayPalButton
                        commit={this.state.commit}
                        env={this.state.env}
                        style={this.state.style}
                        createOrder={(data, actions) => this.payment(data, actions)}
                        onApprove={(data, actions) => this.onApprove(data, actions)}
                    />}
                </div>
                <TextStyle className="Paypal-footer" alias="FinePrint">
                    <span className="footer-icon-span">
                        <img className="custom-lock paypal-footer-icon" src={lock} alt="The information you provided us will be treated in accordance with this Privacy Statement" />
                    </span>
                    <div className="footer-text">
                        The information you provided us will be treated in accordance with this{' '}
                        <a href="https://www.telstra.com.au/privacy/privacy-statement" className="footer-ref" target="_blank" rel="noopener noreferrer">
                            {' '}
                            Privacy Statement
                        </a>
                    </div>
                </TextStyle>
            </div>
        );
    }
    async payment(data, actions) {
        let paymentData = '';
        const surchargeResult = await this.callSurchargeRates();
        const { payment: { hostedFields: { initialised = false } = {} } = {} } = this.props.state;
        const { paypalPayment } = this.props.actions;
        const { dueToday } = getSepratedSurchargeData(surchargeResult.data.surcharges)
        paymentData = {
            flow: 'checkout',
            currency: 'AUD',
            amount: dueToday.revisedAmount,
            intent: 'sale',
            displayName: 'Telstra Prepaid',
            enableShippingAddress: false,
            lineItems: [
                {
                    quantity: 1,
                    unitAmount: dueToday.originalAmount,
                    name: 'Telstra Recharge amount',
                    kind: 'debit'
                },
                {
                    quantity: 1,
                    unitAmount: dueToday.surchargeAmount,
                    name: 'PayPal surcharge fee',
                    kind: 'debit'
                }
            ]
        };
        if (initialised) {
            return paypalPayment(paymentData, actions).then((paymentId) => {
                return paymentId;
            });
        }
        return null;
    }
    onApprove(data) {
        let payload = null;
        const {
            uiState: { loggedIn = false }
        } = this.props.state.app.appData;
        const { app: { appData: { customerInfo = {} } } = {} } = this.props.state;
        const { customerAccountUUID = '2fa85f64-5717-4562-b3fc-2c963f66afa8', identityProfileId = 'required' } = customerInfo;
        payload = {
            customerUUID: customerAccountUUID,
            paymentAmount: this.props.amount,
        };
        if (loggedIn) {
            payload.identityProfileId = identityProfileId;
        }
        const { payment: { hostedFields: { initialised = false } = {} } = {} } = this.props.state;
        const { paypalonAuthorize } = this.props.actions;
        if (initialised) {
            addDataLayerCartPrice('paymentType', 'PayPal');
            addDataLayerEventInfo('paypalPaymentMethod');
            paypalonAuthorize(payload, data);
        }
    }
    renderErrors() {
        const { errors } = this.props.state.payment;
        let errorText = '';
        let errorHeading = '';
        if (errors && (errors.hasError || errors.errorCode === PAYMENT_ERROR)) {
            errorHeading = errors.errorHeading;
            errorText = errors.errorBody;
        }
        if (errorHeading !== '') {
            return (
                <div className="my-4" id="server-error-description">
                    <MessageSection
                        variant="Error"
                        developmentUrl={ableSpriteSheet}
                        description={errorText}
                        titleText={errorHeading} />
                </div>
            );
        }
        return null;
    }
    callSurchargeRates() {
        const { amount = 0, getSurchargeRates } = this.props;
        const payload = {
            paymentAmount: amount,
            paymentType: 'PAYPAL',
            paymentNonce: null,
            id: null
        };
        const CancelToken = axios.CancelToken;
        surchargeCallSource = CancelToken.source();
        return getSurchargeRates(payload, surchargeCallSource.token);
    }
}
export default Paypal;
