export const APP_INIT_DONE = 'APP_INIT_DONE';
export const CUSTOMER_STATE_CHECK_COMPLETE = 'CUSTOMER_STATE_CHECK_COMPLETE';
export const STORE_USER_ACCESS_TOKEN = 'STORE_USER_ACCESS_TOKEN';
export const RECHECK_AUTHENTICATION = 'RECHECK_AUTHENTICATION';
export const LOGIN_CHECK_STATUS_UPDATE = 'LOGIN_CHECK_STATUS_UPDATE';
export const REQUEST_NUMBER_CHECK = 'REQUEST_NUMBER_CHECK';
export const NUMBER_CHECK_DONE = 'NUMBER_CHECK_DONE';
export const NUMBER_CHECK_ERROR = 'NUMBER_CHECK_ERROR';
export const REQUEST_CUSTOMER_CHECK = 'REQUEST_CUSTOMER_CHECK';
export const LOCK_CUSTOMER_DETAILS_STATE = 'LOCK_CUSTOMER_DETAILS_STATE';
export const CUSTOMER_CHECK_DONE = 'CUSTOMER_CHECK_DONE';
export const CUSTOMER_CHECK_INIT = 'CUSTOMER_CHECK_INIT';
export const CUSTOMER_CHECK_ERROR = 'CUSTOMER_CHECK_ERROR';
export const CUSTOMER_DATA_FETCH_INIT = 'CUSTOMER_DATA_FETCH_INIT';
export const CUSTOMER_DATA_FETCH_ERROR = 'CUSTOMER_DATA_FETCH_ERROR';
export const CUSTOMER_DATA_FETCH_SUCCESS = 'CUSTOMER_DATA_FETCH_SUCCESS';
export const FETCH_OFFER_ERROR = 'FETCH_OFFER_ERROR';
export const FETCH_OFFER_SUCCESS = 'FETCH_OFFER_SUCCESS';
export const DOCUMENT_VALIDATION_INIT = 'DOCUMENT_VALIDATION_INIT';
export const DOCUMENT_VALIDATION_SUCCESS = 'DOCUMENT_VALIDATION_SUCCESS';
export const DOCUMENT_VALIDATION_ERROR = 'DOCUMENT_VALIDATION_ERROR';
export const PORTING_CHECK_INIT = 'PORTING_CHECK_INIT';
export const PORTING_VALIDATION_SUCCESS = 'PORTING_VALIDATION_SUCCESS';
export const PORTING_VALIDATION_ERROR = 'PORTING_VALIDATION_ERROR';
export const RESET_ERRORS = 'RESET_ERRORS';
export const RESET_PAYMENT_ERRORS = 'RESET_PAYMENT_ERRORS';
export const RESET_CUSTOMER_DETAILS_FORM = 'RESET_CUSTOMER_DETAILS_FORM';
export const RESET_CONTACT_DETAILS_FORM = 'RESET_CONTACT_DETAILS_FORM';
export const RESET_REGISTRATION_FORM = 'RESET_REGISTRATION_FORM';
export const RESET_ADDRESS_DETAILS_FORM = 'RESET_ADDRESS_DETAILS_FORM';
export const RESET_AUTHORISED_DETAILS_FORM = 'RESET_AUTHORISED_DETAILS_FORM';
export const LOCK_STATE = 'LOCK_STATE';
export const LOCK_REVIEW_DETAILS_STATE = 'LOCK_REVIEW_DETAILS_STATE';
export const LOCK_PAYMENT_STATE = 'LOCK_PAYMENT_STATE';
export const ADDRESS_LOOKUP_SUCCESS = 'ADDRESS_LOOKUP_SUCCESS';
export const ADDRESS_LOOKUP_FAILURE = 'ADDRESS_LOOKUP_FAILURE';
export const ADDRESS_VERIFICATION_INIT = 'ADDRESS_VERIFICATION_INIT';
export const ADDRESS_VERIFICATION_SUCCESS = 'ADDRESS_VERIFICATION_SUCCESS';
export const ADDRESS_VERIFICATION_FAILURE = 'ADDRESS_VERIFICATION_FAILURE';
export const ADDRESS_VERIFICATION_REQUIRED = 'ADDRESS_VERIFICATION_REQUIRED';
export const ADDRESS_VERIFICATION_REQUIRED_STATUS = 'ADDRESS_VERIFICATION_REQUIRED_STATUS';
export const WELCOME_INIT_DONE = 'WELCOME_INIT_DONE';
export const EXISTING_NUMBER_INIT_DONE = 'EXISTING_NUMBER_INIT_DONE';
export const PORTING_TERMS_INIT_DONE = 'PORTING_TERMS_INIT_DONE';
export const YOUR_DETAILS_INIT_DONE = 'YOUR_DETAILS_INIT_DONE';
export const CONTACT_DETAILS_INIT_DONE = 'CONTACT_DETAILS_INIT_DONE';
export const AUTHORISED_REPRESENTATIVE_INIT_DONE = 'AUTHORISED_REPRESENTATIVE_INIT_DONE';
export const ADDRESS_DETAILS_INIT_DONE = 'ADDRESS_DETAILS_INIT_DONE';
export const IDENTITY_DETAILS_INIT_DONE = 'IDENTITY_DETAILS_INIT_DONE';
export const OFFER_DETAILS_INIT_DONE = 'OFFER_DETAILS_INIT_DONE';
export const REVIEW_DETAILS_INIT_DONE = 'REVIEW_DETAILS_INIT_DONE';
export const ACTIVATION_GUIDE_INIT_DONE = 'ACTIVATION_GUIDE_INIT_DONE';
export const PAYMENT_INIT_DONE = 'PAYMENT_INIT_DONE';
export const ERROR_TYPE_NULLIFY = 'ERROR_TYPE_NULLIFY';
export const REGISTER_INIT_DONE = 'REGISTER_INIT_DONE';
export const YOUR_CONTACT_DETAILS_INIT_DONE = 'YOUR_CONTACT_DETAILS_INIT_DONE';
export const WELCOME_INIT_ERROR = 'WELCOME_INIT_ERROR';
export const EXISTING_NUMBER_INIT_ERROR = 'EXISTING_NUMBER_INIT_ERROR';
export const PORTING_TERMS_INIT_ERROR = 'PORTING_TERMS_INIT_ERROR';
export const YOUR_DETAILS_INIT_ERROR = 'YOUR_DETAILS_INIT_ERROR';
export const CONTACT_DETAILS_INIT_ERROR = 'CONTACT_DETAILS_INIT_ERROR';
export const AUTHORISED_REPRESENTATIVE_INIT_ERROR = 'AUTHORISED_REPRESENTATIVE_INIT_ERROR';
export const ADDRESS_DETAILS_INIT_ERROR = 'ADDRESS_DETAILS_INIT_ERROR';
export const IDENTITY_DETAILS_INIT_ERROR = 'IDENTITY_DETAILS_INIT_ERROR';
export const OFFER_DETAILS_INIT_ERROR = 'OFFER_DETAILS_INIT_ERROR';
export const REVIEW_DETAILS_INIT_ERROR = 'REVIEW_DETAILS_INIT_ERROR';
export const ACTIVATION_GUIDE_INIT_ERROR = 'ACTIVATION_GUIDE_INIT_ERROR';
export const PAYMENT_INIT_ERROR = 'PAYMENT_INIT_ERROR';
export const REGISTER_INIT_ERROR = 'REGISTER_INIT_ERROR';
export const YOUR_CONTACT_DETAILS_INIT_ERROR = 'YOUR_CONTACT_DETAILS_INIT_ERROR';
export const SERVICE_ACTIVATION_INIT = 'SERVICE_ACTIVATION_INIT';
export const SERVICE_ACTIVATION_SUCCESS = 'SERVICE_ACTIVATION_SUCCESS';
export const SERVICE_ACTIVATION_ERROR = 'SERVICE_ACTIVATION_ERROR';
export const CART_SUBMISSION_INIT = 'CART_SUBMISSION_INIT';
export const CART_SUBMISSION_SUCCESS = 'CART_SUBMISSION_SUCCESS';
export const CART_SUBMISSION_ERROR = 'CART_SUBMISSION_ERROR';
export const UPDATE_VIEW_PORT = 'UPDATE_VIEW_PORT';
export const REQUEST_IN_PROGRESS = 'REQUEST_IN_PROGRESS';
export const REQUEST_COMPLETE = 'REQUEST_COMPLETE';
export const REQUEST_CANCELLED = 'REQUEST_CANCELLED';
export const FORM_FIELD_CHANGE = '@@redux-form/CHANGE';
export const TOGGLE_DEBUGGER = 'TOGGLE_DEBUGGER';
export const GO_BACK = 'GO_BACK';
export const REQUEST_EXISTING_CUSTOMER = 'REQUEST_EXISTING_CUSTOMER';
export const EXISTING_CUSTOMER_FETCH_SUCCESS = 'EXISTING_CUSTOMER_FETCH_SUCCESS';
export const EXISTING_CUSTOMER_FETCH_ERROR = 'EXISTING_CUSTOMER_FETCH_ERROR';
export const RESET_EXISTING_CUSTOMER_PROCESS = 'RESET_EXISTING_CUSTOMER_PROCESS';
export const CREATE_SHOPPING_CART_SUCCESS = 'CREATE_SHOPPING_CART_SUCCESS';
export const CREATE_SHOPPING_CART_ERROR = 'CREATE_SHOPPING_CART_ERROR';
export const UPDATE_SHOPPING_CART_INIT = 'UPDATE_SHOPPING_CART_INIT';
export const UPDATE_SHOPPING_CART_SUCCESS = 'UPDATE_SHOPPING_CART_SUCCESS';
export const UPDATE_SHOPPING_CART_ERROR = 'UPDATE_SHOPPING_CART_ERROR';
export const REGISTER_CUSTOMER_ERROR = 'REGISTER_CUSTOMER_ERROR';
export const REGISTER_CUSTOMER_SUCCESS = 'REGISTER_CUSTOMER_SUCCESS';
export const NETWORK_CONNECTION_STATUS = 'NETWORK_CONNECTION_STATUS';
export const ESIM_OFFER_ELIGIBILITY_CHECK_SUCCESS = 'ESIM_OFFER_ELIGIBILITY_CHECK_SUCCESS';
export const ESIM_OFFER_ELIGIBILITY_CHECK_ERROR = 'ESIM_OFFER_ELIGIBILITY_CHECK_ERROR';
export const UPDATE_SELECTED_ESIM_OFFER = 'UPDATE_SELECTED_ESIM_OFFER';
export const STORE_LANDING_ADDRESS = 'STORE_LANDING_ADDRESS';
export const SELECT_DEVICE_TYPE = 'SELECT_DEVICE_TYPE';
export const SELECT_ACTIVATION_FLOW_TYPE = 'SELECT_ACTIVATION_FLOW_TYPE';
export const BRAINTREE_INIT_DONE = 'BRAINTREE_INIT_DONE';
export const BRAINTREE_DEVICE_DATA = 'BRAINTREE_DEVICE_DATA';
export const BILLPAY_HOSTED_FIELD_INITIALISED = 'BILLPAY_HOSTED_FIELD_INITIALISED';
export const BILLPAY_HOSTED_FIELD_EMPTY = 'BILLPAY_HOSTED_FIELD_EMPTY';
export const UPDATE_SELECTED_OFFER_DATA = 'UPDATE_SELECTED_OFFER_DATA';
export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD';
export const INCREASE_PROGRESS_BAR_STEP = 'INCREASE_PROGRESS_BAR_STEP';
export const DECREASE_PROGRESS_BAR_STEP = 'DECREASE_PROGRESS_BAR_STEP';
export const UPDATE_PROGRESS_BAR_STEP_CUSTOM_DATA = 'UPDATE_PROGRESS_BAR_STEP_CUSTOM_DATA';
export const ANALYTICS_PAYMENT_METHOD = 'ANALYTICS_PAYMENT_METHOD';
export const SAVING_CARD_OPTION = 'SAVING_CARD_OPTION';

export const PRE_PORT_VERIFICATION_INIT = 'PRE_PORT_VERIFICATION_INIT';
export const PRE_PORT_VERIFICATION_SUCCESS = 'PRE_PORT_VERIFICATION_SUCCESS';
export const PRE_PORT_VERIFICATION_ERROR = 'PRE_PORT_VERIFICATION_ERROR';

export const RECIEVE_SMS_CODE_INIT = 'RECIEVE_SMS_CODE_INIT';
export const RECIEVE_SMS_CODE_ERROR = 'RECIEVE_SMS_CODE_ERROR';
export const RECIEVE_SMS_CODE_SUCCESS = 'RECIEVE_SMS_CODE_SUCCESS';

export const RESET_PORTING_STATE = 'RESET_PORTING_STATE';

export const SHOW_PORTING_MODAL = 'SHOW_PORTING_MODAL';
export const HIDE_LOADING_INDICATOR = 'HIDE_LOADING_INDICATOR';
export const SHOW_LOADING_INDICATOR = 'SHOW_LOADING_INDICATOR';
export const PORTING_RESET_BACK_NAVIGATION = 'PORTING_RESET_BACK_NAVIGATION';

export const SET_SELECTED_DEVICE = 'SET_SELECTED_DEVICE';
export const UPDATE_CURRENT_PLAN = 'UPDATE_CURRENT_PLAN';

export const SET_AUTO_RECHARGE = 'SET_AUTO_RECHARGE';
export const RESET_AUTO_RECHARGE = 'RESET_AUTO_RECHARGE';

export const ESIM_HEALTH_CHECK_ERROR = 'ESIM_HEALTH_CHECK_ERROR';
export const ESIM_HEALTH_CHECK_SUCCESS = 'ESIM_HEALTH_CHECK_SUCCESS';

export const RETRIEVE_ESIM_PARAMS_ERROR = 'RETRIEVE_ESIM_PARAMS_ERROR';
export const RETRIEVE_ESIM_PARAMS_SUCCESS = 'RETRIEVE_ESIM_PARAMS_SUCCESS';

export const REQUEST_MSISDN_INIT = 'REQUEST_MSISDN_INIT';
export const REQUEST_MSISDN_SUCCESS = 'REQUEST_MSISDN_SUCCESS';
export const REQUEST_MSISDN_ERROR = 'REQUEST_MSISDN_ERROR';

export const ESIM_PROFILE_INIT = 'ESIM_PROFILE_INIT';
export const ESIM_PROFILE_SUCCESS = 'ESIM_PROFILE_SUCCESS';
export const ESIM_PROFILE_ERROR = 'ESIM_PROFILE_ERROR';
export const SHOW_SKIP_RECHARGE_MODAL = 'SHOW_SKIP_RECHARGE_MODAL';
export const SHOW_UDATE_CONTACT_MODAL = 'SHOW_UDATE_CONTACT_MODAL';

export const RECIEVE_OTC_INIT = 'RECIEVE_OTC_INIT';
export const VALIDATE_OTC_SUCCESS = 'VALIDATE_OTC_SUCCESS';
export const VALIDATE_OTC_ERROR = 'VALIDATE_OTC_ERROR';
export const RESET_OTC_STATE = 'RESET_OTC_STATE';

export const RESET_EDIT_CONTACT_STATE = 'RESET_EDIT_CONTACT_STATE';
export const OTP_SENT_SUCCESS = 'OTP_SENT_SUCCESS';
export const OTP_SENT_ERROR = 'OTP_SENT_ERROR';
export const OTP_SENT_INIT = 'OTP_SENT_INIT';

export const UPDATE_CONTACT_MEDIUM_INIT = 'UPDATE_CONTACT_MEDIUM_INIT';
export const UPDATE_CONTACT_MEDIUM_SUCCESS = 'UPDATE_CONTACT_MEDIUM_SUCCESSS';
export const UPDATE_CONTACT_MEDIUM_ERROR = 'UPDATE_CONTACT_MEDIUM_ERROR';

export const STORE_USER_LOA = 'STORE_USER_LOA';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_MOBILE_SUCCESS = 'UPDATE_MOBILE_SUCCESS';
export const NO_UPDATE_EXIS_NUM = 'NO_UPDATE_EXIS_NUM';
export const UPDATE_CUSTOMER_BUSINESS_DATA = 'UPDATE_CUSTOMER_BUSINESS_DATA';
export const ADD_CUSTOMER_BUSINESS = 'ADD_CUSTOMER_BUSINESS';
export const SET_PHONE_VER_LIMIT_INFO = 'SET_PHONE_VER_LIMIT_INFO';

export const ADDRESS_VERIFICATION_INIT_UPDATE_CONTACT_DETAILS = 'ADDRESS_VERIFICATION_INIT_UPDATE_CONTACT_DETAILS';
export const ADDRESS_VERIFICATION_SUCCESS_UPDATE_CONTACT_DETAILS = 'ADDRESS_VERIFICATION_SUCCESS_UPDATE_CONTACT_DETAILS';
export const ADDRESS_VERIFICATION_FAILURE_UPDATE_CONTACT_DETAILS = 'ADDRESS_VERIFICATION_FAILURE_UPDATE_CONTACT_DETAILS';

export const UNAUTH_UPDATE_ADD_NUMBER_SKIP = 'UNAUTH_UPDATE_ADD_NUMBER_SKIP';
export const YOUR_CONTACT_DETAILS_RESET_ERROR = 'YOUR_CONTACT_DETAILS_RESET_ERROR';
export const BUSINESS_REGISTRATION_SET_ERROR = 'BUSINESS_REGISTRATION_SET_ERROR';
export const BUSINESS_REGISTRATION_SET_FORM_VALID = 'BUSINESS_REGISTRATION_SET_FORM_VALID';
export const RESET_OFFER = 'RESET_OFFER';

export const ABN_ACN_VALIDATION_REQUEST_INIT = 'ABN_ACN_VALIDATION_REQUEST_INIT';
export const ABN_ACN_VALIDATION_REQUEST_SUCCESS = 'ABN_ACN_VALIDATION_REQUEST_SUCCESS';
export const ABN_ACN_VALIDATION_REQUEST_ERROR = 'ABN_ACN_VALIDATION_REQUEST_ERROR';
export const ABN_ACN_VALIDATION_RESET = 'ABN_ACN_VALIDATION_RESET';
export const REMOVE_ABN_ACN_VALIDATION_MESAGE = 'REMOVE_ABN_ACN_VALIDATION_MESAGE';
export const ABN_ACN_VALIDATION_REQUEST_LIMIT_EXCEEDED = 'ABN_ACN_VALIDATION_REQUEST_LIMIT_EXCEEDED';

export const SET_SELECTED_BUSINESS = 'SET_SELECTED_BUSINESS';
export const SET_SELECTED_BUSINESS_ERROR = 'SET_SELECTED_BUSINESS_ERROR';
export const SET_MAXED_OUT_BUSINESS_NUMBER = 'SET_MAXED_OUT_BUSINESS_NUMBER';

export const SET_BUSINESS_CONTACT_EDITING_FIELD = 'SET_BUSINESS_CONTACT_EDITING_FIELD';
export const SET_BUSINESS_CONTACT_EDITING_STEP = 'SET_BUSINESS_CONTACT_EDITING_STEP';
export const SET_BUSINESS_CONTACT_EDITING_VALUE = 'SET_BUSINESS_CONTACT_EDITING_VALUE';

export const UPDATE_BIZ_CONTACT_MEDIUM_INIT = 'UPDATE_BIZ_CONTACT_MEDIUM_INIT';
export const UPDATE_BIZ_CONTACT_MEDIUM_SUCCESS = 'UPDATE_BIZ_CONTACT_MEDIUM_SUCCESSS';
export const UPDATE_BIZ_CONTACT_MEDIUM_ERROR = 'UPDATE_BIZ_CONTACT_MEDIUM_ERROR';

export const VERIFY_BUSINESS_ADDRESS_INIT = 'VERIFY_BUSINESS_ADDRESS_INIT';
export const VERIFY_BUSINESS_ADDRESS_SUCCESS = 'VERIFY_BUSINESS_ADDRESS_SUCCESSS';
export const VERIFY_BUSINESS_ADDRESS_ERROR = 'VERIFY_BUSINESS_ADDRESS_ERROR';

export const RESET_BUSINESS_ADDRESS_META = 'RESET_BUSINESS_ADDRESS_META';

export const CREATE_BUSINESS_ACCOUNT_INIT = 'CREATE_BUSINESS_ACCOUNT_INIT';
export const CREATE_BUSINESS_ACCOUNT_SUCCESS = 'CREATE_BUSINESS_ACCOUNT_SUCCESSS';
export const CREATE_BUSINESS_ACCOUNT_ERROR = 'CREATE_BUSINESS_ACCOUNT_ERROR';

export const SET_UPDATE_BUSINESS_CONTACTS_FORM_ERRORS = 'SET_UPDATE_BUSINESS_CONTACTS_FORM_ERRORS';
export const EDIT_CONTACT_FORM_SUBMITTED = 'EDIT_CONTACT_FORM_SUBMITTED';

export const PERSONAL_EMAIL_OTC_SENT_SUCCESS = 'PERSONAL_EMAIL_OTC_SENT_SUCCESS';
export const PERSONAL_EMAIL_OTC_SENT_ERROR = 'PERSONAL_EMAIL_OTC_SENT_ERROR';
export const PERSONAL_EMAIL_OTC_SENT_INIT = 'PERSONAL_EMAIL_OTC_SENT_INIT';
export const VERIFY_PERSONAL_EMAIL_SUCCESS = 'VERIFY_PERSONAL_EMAIL_SUCCESS';
export const VERIFY_PERSONAL_EMAIL_ERROR = 'VERIFY_PERSONAL_EMAIL_ERROR';
export const VERIFY_PERSONAL_EMAIL_INIT = 'VERIFY_PERSONAL_EMAIL_INIT';
export const RESET_PERSONAL_EMAIL_OTC = 'RESET_PERSONAL_EMAIL_OTC';
export const RESET_PERSONAL_EMAIL = 'RESET_PERSONAL_EMAIL';

export const SET_FULL_PAGE_ERROR_APP_FLAG = 'SET_FULL_PAGE_ERROR_APP_FLAG';
export const FETCH_SURCHARGE_SUCCESS = 'FETCH_SURCHARGE_SUCCESS';
export const FETCH_SURCHARGE_FAILURE = 'FETCH_SURCHARGE_FAILURE';
export const FETCH_SURCHARGE_LOADING = 'FETCH_SURCHARGE_LOADING';
export const RESET_SURCHARGE_RATES = 'RESET_SURCHARGE_RATES';
export const STORE_BRAINTREE_VALIDATED_DATA = 'STORE_BRAINTREE_VALIDATED_DATA';
export const SET_IS_SELECTED_DEBIT_CARD = 'SET_IS_SELECTED_DEBIT_CARD';
export const FETCH_SURCHARGE_TABLE_DATA_SUCCESS = 'FETCH_SURCHARGE_TABLE_DATA_SUCCESS';
export const FETCH_SURCHARGE_TABLE_DATA_FAILURE = 'FETCH_SURCHARGE_TABLE_DATA_FAILURE';